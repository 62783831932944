import React from "react"
import "./article-body.scss"
import { ArticleBodyProps } from "../../types/components"
import Img from "../shared/img"
import AuthorCard from "../shared/author-card"

const ArticleBody: React.FC<ArticleBodyProps> = ({
  featuredImage,
  title,
  tagsTitle,
  tags,
  content,
  theAuthor,
  author,
}) => {
  return (
    <div className="article-body">
      <div className="article-body__wrapper container--xl">
        <div className="article-body__left tab--width">
          <div className="article-body__left__tags">
            {tagsTitle && (
              <span
                className="article-body__left__tags__title"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-easing="ease-out"
              >
                {tagsTitle}
              </span>
            )}
            {tags && (
              <span
                className="article-body__left__tags__keywords"
                data-aos="fade-up"
                data-aos-delay="150"
                data-aos-easing="ease-out"
              >
                {tags}
              </span>
            )}
          </div>
        </div>
        <div className="article-body__right content--width">
          <h1
            data-aos="fade-up"
            data-aos-delay="120"
            data-aos-easing="ease-out"
          >
            {title}
          </h1>
          <div
            className="article-body__right__featured-image"
            data-aos="fade-up"
            data-aos-delay="320"
            data-aos-easing="ease-out"
          >
            <Img imgProps={featuredImage} />
          </div>
          <div
            className="article-body__right__content"
            dangerouslySetInnerHTML={{ __html: content }}
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-easing="ease-out"
          />
        </div>
      </div>
      {author && <AuthorCard theAuthor={theAuthor} author={author} />}
    </div>
  )
}

export default ArticleBody
