import React from "react"
import { graphql, PageProps } from "gatsby"
import { ArticlePageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ArticleBody from "../components/single-article/article-body"

const SingleArticle: React.FC<PageProps<ArticlePageProps>> = ({ data }) => {
  const { tagsTitle, tags, articleContent, theAuthor, authorSelector } =
    data?.PAGE_CONTENT?.singleArticleOptions

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <ArticleBody
        featuredImage={data?.PAGE_CONTENT?.featuredImage?.node}
        title={data?.PAGE_METADATA?.title}
        tagsTitle={tagsTitle}
        tags={tags}
        content={articleContent}
        theAuthor={theAuthor}
        author={authorSelector}
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpArticle(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpArticle(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      featuredImage {
        node {
          altText
          sizes
          sourceUrl
          srcSet
        }
      }
      singleArticleOptions {
        tagsTitle
        tags
        articleContent
        theAuthor
        authorSelector {
          ... on WpTeam {
            id
            uri
            title
            featuredImage {
              node {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
            singleTeam {
              position
            }
          }
        }
      }
    }
  }
`

export default SingleArticle
