import React from "react"
import "./author-card.scss"
import { AuthorCardProps } from "../../types/components"
import { AuthorSelectProps } from "../../types/shared-singulars"
import { Link } from "gatsby"
import Img from "../shared/img"
import { CircleArrow } from "../elements/svg-elements"

const AuthorCard: React.FC<AuthorCardProps> = ({ theAuthor, author }) => {
  return (
    <div className="author-card">
      <div className="author-card__wrapper container--xl">
        <div className="author-card__left tab--width">
          <span />
        </div>
        <div className="author-card__right content--width">
          <h2>{theAuthor}</h2>
          <div>
            {author &&
              author.map((item: AuthorSelectProps, index: number) => (
                <Link
                  key={index}
                  to={item.uri}
                  className="author-card__right__item"
                  data-aos="fade-up"
                  data-aos-delay={(index + 1) * 80}
                  data-aos-easing="ease-out"
                >
                  <div className="author-card__right__item__image">
                    <Img imgProps={item?.featuredImage?.node} />
                  </div>
                  <div className="author-card__right__item__nodes">
                    <h6>{item.title}</h6>
                    <p>{item?.singleTeam?.position}</p>
                    <CircleArrow />
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthorCard
